<template>
  <div v-if="pageLoaded">
    <div class="main-container">
      <div class="form-container">
        <div v-if="invoice.status == statuses.paid" style="text-align: center; color: #0c0; font-weight: bolder; font-size: 20px; padding: 20px 0px;">{{lang.invoicePaid}} <tz-icon name="passed" /> </div>
        <div class="form-wrapper">
          <div v-if="invoice && invoice.invoiceId">
          <div class="row">
            <div class="d-flex" style="align-items: center;">
              <div class="w-100">
                <h3>{{ businessAccount.name }}</h3>
              </div>
              <div style="width: 50px;">
                <div class="col-2">
                  <tz-image round :src="businessAccount.logo"></tz-image>
                </div>
              </div>
            </div>
            <div class="invoice-row col-10">
              <small class="invoice-labels"> {{lang.invoiceTitle }}</small>
              <h2>
                {{ invoice.title}}
              </h2>
            </div>
            <div class="invoice-row col-12">
              <small class="invoice-labels"> {{lang.invoiceId }}</small>
              <div>
                {{ invoice.invoiceId}}
              </div>
            </div>
            <div class="invoice-row col-12">
              <small class="invoice-labels"> {{lang.billTo }}</small>
              <div>
                {{ invoice.billToName }}
              </div>
            </div>
            <div class="invoice-row col-12" v-if="invoice.billToEmail">
              <small class="invoice-labels"> {{lang.billToEmail }}</small>
              <div>
                {{ invoice.billToEmail }}
              </div>
            </div>
            <div class="invoice-row col-12" v-if="invoice.billToAddress">
              <small class="invoice-labels"> {{lang.billToAddress }}</small>
              <div>
                {{ invoice.billToAddress }}
              </div>
            </div>
            <div class="invoice-row col-12">
              <small class="invoice-labels"> {{lang.invoiceStatus }}</small>
              <div class="invoice-status">
                <span :style="{color: status[1][invoice.status]}">{{ lang[status[0][invoice.status]] }}</span>
              </div>
            </div>
            <div class="invoice-row col-6" v-if="invoice.dueDate || invoice.nextInstallmentDueDate">
              <small class="invoice-labels"> {{lang.dueDate }}</small>
              <div>
                <small v-if="invoice.dueDate || invoice.nextInstallmentDueDate">{{ date(invoice.dueDate || invoice.nextInstallmentDueDate) }}</small>
                <small v-else>{{lang.na}}</small>
              </div>
            </div>
          </div>

          <div class="invoice-row" v-if="invoice.type == 1">
            <h3 v-if="isAceInvoice" style="text-align: center"> {{lang.students }}</h3>
            <h3 v-else style="text-align: center"> {{lang.items }}</h3>
            <div class="invoice-display-items no-print">
              <tz-collapse v-model="activeItem">
                <tz-collapse-item :name="i" v-for="(item, i) in invoice.items" :key="i">
                  <template #title>
                    <div class="items-title">
                      <div style="width: 100%; min-width: 200px; max-width: 240px !important;">
                        <div class="tz-ellipsis" style="max-width: 200px;">
                          {{ item.itemDescription }}
                        </div>
                      </div>
                      <div class="text-left" style="font-weight: bolder; widows: auto;">
                        ({{ currency(parseFloat(item.unitPrice - (item.discountAmount || 0)) * parseInt(item.quantity), invoice.currencyCode) }} <small style=""> {{ invoice.currencyCode }}</small>)
                      </div>
                    </div>
                  </template>
                  <div style="padding: 4px;">
                    <div class="item-detail">
                      <div style="width: 200px">
                        <small v-if="isAceInvoice" class="invoice-labels text-semi-dark">
                          {{ lang.name }}:
                        </small>
                        <small v-else class="invoice-labels">
                          {{ lang.description }}:
                        </small>
                      </div>
                      <div class="fw-bolder text-semi-dark">
                        <span>{{ item.itemDescription }}</span>
                      </div>
                    </div>
                    <div class="item-detail" v-if="isAceInvoice">
                      <div style="width: 200px">
                        <small class="invoice-labels text-semi-dark">
                          {{ lang.grade }}:
                        </small>
                      </div>
                      <div class="fw-bolder text-semi-dark">
                        <span>{{ getGrade(item.customId) }}</span>
                      </div>
                    </div>
                    <div v-if="item.subItems && item.subItems.length" style="background-color: #7771; border-radius: 5px; padding: 3px; margin-top: 15px; ">
                      <div class="fw-bolder text-semi-dark">{{ lang.subItems }}</div>
                      <div class="item-detail" v-for="(sub, j) in item.subItems" :key="j+ 'dfd'">
                        <div style="width: 140px">
                          <small class="invoice-labels">
                            {{ sub.itemDescription }}:
                          </small>
                        </div>
                        <div style="" class="fw-bold text-semi-dark">
                          <span>{{ currency(sub.unitPrice || 0, invoice.currencyCode) }}</span> <small> {{ invoice.currencyCode }}</small>
                        </div>
                      </div>
                    </div>
                    <div class="item-detail" v-if="!isAceInvoice">
                      <div style="width: 140px">
                        <small class="invoice-labels">
                          {{ lang.quantity }}:
                        </small>
                      </div>
                      <div>
                        <span>{{ item.quantity }}</span>
                      </div>
                    </div>
                    <div class="item-detail" v-if="item.discountAmount">
                      <div style="width: 140px">
                        <small class="invoice-labels" v-if="isAceInvoice">
                          {{ lang.scholarship }}:
                        </small>
                        <small class="invoice-labels" v-else>
                          {{ lang.discountAmount }}
                        </small>
                      </div>
                      <div style="font-weight: 500;" v-if="isAceInvoice" class="fw-bold text-primary">
                        <span>-{{ currency(item.discountAmount, invoice.currencyCode) }}</span> <small> {{ invoice.currencyCode }}</small>
                      </div>
                      <div v-else class="fw-bold">
                        <span>-{{ currency(item.discountAmount, invoice.currencyCode) }}</span> <small> {{ invoice.currencyCode }}</small>
                      </div>
                    </div>
                    <div class="item-detail">
                      <div style="width: 140px">
                        <small class="invoice-labels" v-if="isAceInvoice">
                          {{ lang.total }}:
                        </small>
                        <small class="fw-bolder text-semi-dark" v-else>
                          {{ lang.unitPrice }}
                        </small>
                      </div>
                      <div style="color: #111; font-weight: bold;" v-if="isAceInvoice">
                        <span>{{ currency(item.unitPrice - (item.discountAmount || 0), invoice.currencyCode) }}</span> <small> {{ invoice.currencyCode }}</small>
                      </div>
                      <div v-else>
                        <span>{{ currency(item.unitPrice - (item.discountAmount || 0), invoice.currencyCode) }}</span> <small> {{ invoice.currencyCode }}</small>
                      </div>
                    </div>
                  </div>
                </tz-collapse-item>
              </tz-collapse>
            </div>
            <div v-if="invoice.installments && invoice.installments.length" style="margin-top: 25px;">
              <h3 style="text-align: center"> {{lang.installments }}</h3>
              <div class="invoice-display-items no-print">
                <div class="item-detail" style="border-bottom: 1px solid #ccc; padding: 10px 5px">
                  <div style="max-width: 220px" class="w-100">
                    <span class="fw-bolder">
                      {{ lang.installment }}
                    </span>
                  </div>
                  <div class="w-100 fw-bolder" style="text-align: right; text-align: center;">
                    <span>{{ lang.dueDate || '----' }}</span>
                  </div>
                  <div class="w-100 fw-bolder" style="text-align: right; ">
                    <span>{{ lang.amount }}</span>
                  </div>
                </div>
                <div :name="i" v-for="(item, i) in invoice.installments" :key="'installments-'+i">
                  <div style="background-color: #7771; border-radius: 5px; padding: 6px;">
                    <div class="item-detail">
                      <div style="max-width: 220px" class="w-100">
                        <small class="invoice-labels">
                          {{ item.name }}:
                        </small>
                      </div>
                      <div class="w-100" style="text-align: center; font-weight: 500; color: #666; transform: scale(.9);" v-if="item.dueDate">
                        <small>{{  date(item.dueDate) }}</small>
                      </div>
                      <div class="w-100 fw-bold" style="text-align: right; transform: scale(.9);">
                        <span>{{  currency(item.amount, invoice.currencyCode ) }}</span> <small>{{ invoice.currencyCode }}</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="printable">
              <div>
                <table>
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>{{lang.itemDescription }}</th>
                      <th>{{lang.quantity }}</th>
                      <th>{{lang.unitPrice }}(<small style="color: #990099">{{invoice.currencyCode}}</small>)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in this.invoice.items" :key="item">
                      <td>{{ index + 1}}.</td>
                      <td>{{ item.itemDescription}}</td>
                      <td>{{ item.quantity}}</td>
                      <td>{{ currency(item.unitPrice, invoice.currencyCode) }} </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div style="padding-top: 10px;" v-if="invoice.clientNote">
              <small class="invoice-labels"> {{lang.note }}</small>
              <div>{{ invoice.clientNote && invoice.clientNote.length? invoice.clientNote: lang.na  }}</div>
            </div>
          </div>
          <div class="invoice-row" v-else>
            <small class="invoice-labels"> {{lang.invoice }}</small>
            <div class="invoice-display-image" v-if="invoice.fileUrl && (invoice.fileUrl.includes('.jpg') || invoice.fileUrl.includes('.jpeg') || invoice.fileUrl.includes('.png') || invoice.fileUrl.includes('.svg'))">
              <tz-image width="100$" :src="invoice.fileUrl" @click="previewInvoiceImage()" />
            </div>
            <div class="invoice-download">
              <tz-button icon="description" type="dark" @click="downloadInvoice()" round>{{lang.downloadInvoice}}</tz-button>
            </div>
          </div>
          <div class="d-flex">
            <div class="w-100">
              <div class="invoice-row col-6">
                <small class="invoice-labels"> {{lang.total }}</small>
                <div>
                  <span>{{ currency(invoice.amountWithoutTax || 0, invoice.currencyCode) }}</span> <small>{{invoice.currencyCode}}</small>
                </div>
              </div>
            </div>
            <div class="w-100 text-right" v-if="invoice.totalTax">
              <div class="invoice-row col-6" :style="'text-align:' + (invoice.totalTax && !invoice.discountAmount)? 'right;': 'center;'">
                <small class="invoice-labels"> {{lang.tax }}</small>
                <div>
                  {{ currency(invoice.totalTax || 0, invoice.currencyCode) }} <small>{{invoice.currencyCode}}</small>
                </div>
              </div>
            </div>
            <div class="w-100 text-right" v-if="invoice.discountAmount">
              <div class="invoice-row col-6">
                <small class="invoice-labels" v-if="isAceInvoice"> {{lang.scholarship }}</small>
                <small class="invoice-labels" v-else> {{lang.discountAmount }}</small>
                <div class="text-primary">
                  -{{ currency(invoice.discountAmount || 0, invoice.currencyCode) }} <small style="color: #333;">{{invoice.currencyCode}}</small>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex">
            <div class="w-100">
              <div class="invoice-row col-6">
                <small class="invoice-labels"> {{lang.totalPaid }}</small>
                <div style="font-weight: bold;">
                  {{ currency(invoice.totalPaid || 0, invoice.currencyCode) }} <small style="color: #990099">{{invoice.currencyCode}}</small>
                </div>
              </div>
            </div>
            <div class="w-100 text-right">
              <div class="invoice-row col-6">
                <small class="invoice-labels"> {{lang.grandTotal }}</small>
                <div style="font-weight: bold;">
                  {{ currency(total, invoice.currencyCode) }} <small style="color: #990099">{{invoice.currencyCode}}</small>
                 <!-- <h1> {{ currency(parseInt(invoice.amountWithoutTax) + parseInt(invoice.totalTax) - parseInt(invoice.totalPaid) || 0, invoice.currencyCode) }} <small style="color: #990099">{{invoice.currencyCode}}</small></h1> -->
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
      <tz-sticky :offset-bottom="0" position="bottom" v-if="invoice && invoice.invoiceId">
        <div class="sticky-bottom" style="background: #fff">
          <div class="bottom-total no-print" style="max-width: 500px">
            <div class="w-100">
              <div class="invoice-row">
                <div>
                  <small class="invoice-labels"> {{lang.amountToBePaid }}</small>
                </div>
                <div class="d-flex">
                  <h2>
                    {{ currency(invoice.amountWithoutTax + invoice.totalTax - invoice.totalPaid, invoice.currencyCode) }}
                  </h2>
                  <div style="padding-left: 10; padding-top: 7.5px; color: #990099">
                    <small>{{ invoice.currencyCode }}</small>
                  </div>
                </div>
              </div>
            </div>
            <div style="text-align: right; white-space: nowrap !important;" class="w-100">

              <!-- trigger payment button viewed by none logged in users -->

              <tz-button style="min-width: 150px" v-if="mode == 2 && (invoice.status == statuses.active || invoice.status == statuses.unpaid)" type="primary" round block @click="triggerPayNow" :disabled="invoice.status > statuses.active">
                {{invoice.totalPaid? lang.payAgain: lang.payNow}}
              </tz-button>
              <div class="no-print" v-else-if="mode == 1">
                <tz-popover v-model:show="showActions" theme="dark" :actions="actions" placement="top-end" @select="getAction" >
                  <template #reference="data">
                    <div>
                      {{ data}}
                    </div>
                  </template>
                </tz-popover>
                <tz-icon name="more-o" size="35px" @click="showActions = true" color="#990099"></tz-icon>
              </div>



              <!-- Add payment from other users viewing the invoice -->
              <tz-popup
                v-model:show="showPayNow"
                round
                position="bottom"
                :style="{ height: '50%' }"
              >
                <div>
                  <tz-sticky :offset-top="50">
                    <div>
                      <tz-button type="primary" block>{{ lang.confirmAmount }} ({{invoice.currencyCode}})</tz-button>
                    </div>
                  </tz-sticky>
                  <div class="sub-menu">
                    <div>
                      <tz-cell-group>
                        <tz-cell
                           v-if="!invoice.acceptPartialPayments"
                        >
                          <h2 style="text-align: center">
                            {{ currency(amountLeft, currencyCode) }} <small style="color: #990099"> {{ currencyCode }}</small>
                          </h2>
                        </tz-cell>
                        <tz-field
                          v-model="payment.amount"
                          :label="lang.amount"
                          autofocus
                          required
                          type="number"
                          v-else
                          :placeholder="lang.amountToBePaid"
                        />
                        <div>
                          <small>{{ lang.pendingAmount }}:{{ currency(amountLeft, currencyCode) }}</small><small style="color: #990099"> {{ currencyCode }}</small>
                        </div>
                        <tz-field
                          v-model="payment.note"
                          :label="lang.note"
                          :placeholder="lang.paymentNote"
                        />
                      </tz-cell-group>
                    </div>
                    <div style="margin: 16px; text-align: center">
                      <tz-button
                        @click="payNow()"
                        style="min-width: 250px !important; margin-top: 24px"
                        round
                        type="primary"
                        :disabled="!payment.amount"
                      >
                        {{ lang.continue }}
                      </tz-button>
                    </div>
                  </div>
                </div>
              </tz-popup>
            </div>
          </div>
        </div>
      </tz-sticky>
      <div v-else style="padding: 50px">
          <tz-button round block type="primary" native-type="submit" @click="goTo('/')">
            <tz-icon name="wap-home-o" color="#fff"></tz-icon> {{lang.home}}
          </tz-button>
      </div>
    </div>

    <!-- payment notification modal -->

    <!-- <tz-dialog v-model:show="show" title="Title" show-cancel-button>
      <img src="https://fms.tranzak.me/tzui/apple-3.jpg" />
    </tz-dialog> -->


  </div>
</template>
<script>
import { useRouter } from "vue-router";
import { ref, reactive, onMounted, computed, watch } from "vue";
import { Toast, ImagePreview, Dialog } from 'tzui-vue'
import { getAppId, getLocal, formatCurrency, formatShortDate, getToken, getAcePlatform } from "../../utils";
import { getBusinessAccountInfo } from "@/api/common";
import api from './api'

import {
  payFee,
  updateFee,
  getSpecific,
  getPrevPayment,
} from "@/api/common";

export default {
  name: 'DisplayInvoice',
  data() {
    return {
      businessAccount: {},
      date: formatShortDate,
      currencyCode: 'XAF',
      requestId: '',
      paidViaCash: true,
      paidViaOtherMeans: false,
      showPayNow: false,
      incomeSource: [],
      router: useRouter(),
      actions: [],
      statuses: {
        draft: 1,
        unpaid: 2,
        active: 3,
        paid: 4,
        cancelled: 5,
        closed: 6,
      },
      status: [{
            1: 'draft', 2: 'unpaid', 3: 'partiallyPaid', 4: 'paid', '-1': 'cancelled', 6: 'closed'
        },
        {
            1: '#343a40', 2: '#ffc107', 3: '#990099', 4: '#28a745', '-1': '#dc3545', 6: '#dc3545'
        }],
      action: 0,
      pageLoaded: false,
      showActions: false,
      showAddPayment: false,
      showMarkAsPaid: false,
      paymentSuccessful: false,
      errors: [],
      items: [],
      lang: {},
      mode: 1, //1 for opened as child component, 2 for opened from browser link
      message: '',
      activeItem: [],
      invoice: {},
      appId: getAppId(),
      payment: {
        amount: '',
        note: '',
        paymentSourceId: '',
        id: '',
      },
      baseURL: location.orig
    }
  },
  props: {
    selectedInvoice:{
      type: Object,
      default() {
        title: ''
      }
    }
  },
  computed:{
    isAceInvoice(){
      if(this.invoice && this.invoice.serviceScopeId && this.invoice.serviceScopeId == getAcePlatform()){
        return true;
      }
      return false
    },
    total(){
      return parseInt(this.invoice.amountWithoutTax + this.invoice.totalTax)
    },
    amountLeft(){
      return parseInt(this.invoice.amountWithoutTax + this.invoice.totalTax) - parseInt(this.invoice.totalPaid) || 0;
    }
  },
  watch: {
    selectedInvoice: function(data){
      if(data.invoiceId){
        this.getInvoice(data.invoiceId)
      }
    }
  },
  mounted () {

    const language = getLocal('app-lang') || 'en';
    this.lang = language.includes('fr')? require('@/assets/languages/fr.json'): require('@/assets/languages/en.json');
    this.currencyCode = getLocal('app-currencyCode') || 'XAF';
    console.log(this.selectedInvoice);

    if(this.selectedInvoice && this.selectedInvoice.invoiceId){
      this.getInvoice(this.selectedInvoice.invoiceId);
    }
    else if(this.$route.params && this.$route.params.id){
      this.mode = 2;
      const id = this.$route.params.id;
      this.getInvoice(id);
    }else{
      this.router.push('invoices-history')
    }
    this.baseURL = `${location.origin}/acknowledgement?invoiceId=${this.$route.params.id}`;
  },
  methods: {
    getGrade(customId = ''){
      return customId.split('_')[1] || 0;
    },
    getBusinessAccountInfo(orgId){
      getBusinessAccountInfo(orgId).then(res=>{
        if(res && res.success && res.data){
          this.businessAccount = res.data.orgAccount || {}
        }
      })
    },
    goTo(route){
      this.router.push(route);
    },
    paymentResponse(res) {
      if (res.data && res.data.status > 0 && res.data.transactionId) {
        Toast.loading({
          message: 'Loading...',
          forbidClick: true,
          duration: 0
        });
        this.$router.push(`/acknowledgement?invoiceId=${this.$route.params.id}&transactionId=${res.data.transactionId}&requestId=${this.requestId}`);
      }else {
        Toast.fail(this.lang.operationFailed)
      }
    },
    triggerPayNow(){
      this.payment.amount = this.amountLeft;
      this.showPayNow = true;
      this.payment.id = this.invoice.invoiceId;
      this.payment.paymentSourceId = null;

      console.log(" triggerPayNow this.payment this.payment ", this.payment);
    },
    payNow(){
       const paymentParams = {
          // "currencyCode":this.invoice?.currencyCode || 'XAF',
          // "hostAppId":this.appInfo?.appId,
          transactionAmount: this.payment.amount,
          "note": this.payment.note,
          "returnUrl": this.baseURL,
          id:  this.invoice.invoiceId,
        }

        console.log(" paymentParams ", paymentParams);

      api.createPaymentRequest({...paymentParams}).then(res=>{

        console.log(" createPaymentRequest= createPaymentRequest ", JSON.stringify(res, undefined, 2));

        if(res.success) {
          this.requestId = res.data.requestId
           const mode = 1; // 1= web and 2 = sdk
           // check if mp is in Tranzak app
          window.TzSdk.isTzApp((result) => {
            if(result.data.success){
              window.TzSdk.confirmPayment(
                {
                  isMapi: false, // false meaning the requestId is a fapi transaction
                  amount: res.data.amount, // Transaction amount
                  requestId: res.data.requestId, // Id of the created transaction
                  appName: this.businessAccount.name, // Mini App name or display name
                  currency: res.data.currencyCode, // Currency used in the transaction
                  isPlatformPayment:false
                },
                {
                  "x-app-key": 'TZ-Collections', // Merchant API key
                  "x-app-env": process.env.NODE_ENV, // Merchant API running environment.
                },
                async (result) => {
                  this.paymentResponse(result);
                },
                'PROD'
              )
            }else{
              console.log(" location============||||||||||||||||||========res.data.paymentProcessorReturnUrl||||||||||||||||| ", JSON.stringify(res.data , undefined, 2));
              const token = getToken()? getToken().replaceAll('"', '') : '';
              location = res.data.links.paymentAuthUrl + (token? `&token=${token}`:'');
            }
          });
        }
        // else{
        //   Toast({
        //     message: this.lang.failedToProcessYourRequest,
        //      duration: 8000,
        //     forbidClick: false,
        //   })
        // }
      }).catch(error=>{
        console.log("errorerrorerrorerror ===========>>>>>>>>>>>>||||||||||<<<<<<<<<<<<<========    ",error);
      })
    },

    async getInvoice(id){
      this.pageLoaded = false;
      await api.getInvoice({id}).then(res=>{
        this.pageLoaded = true;
        if(res.success){
          this.invoice = {...this.prepareInvoice(res.data)};
          this.getBusinessAccountInfo(this.invoice.orgId);
        }
      }).catch(err=>{
        this.pageLoaded = false;
        Dialog.confirm({
          message: this.lang.failedToLoadInvoice,
          confirmButtonText: this.lang.yes,
          cancelButtonText: this.lang.no
        }).then(res=>{
          this.getInvoice(this.$route.params.id);
        })
        console.log(err);
      })
    },

    prepareInvoice(data){
      return {...data, acceptPartialPayments: data.acceptPartialPayments == 0 || data.acceptPartialPayments == false? false: true};

    },
    currency: function(value, currency){
      return formatCurrency(value, currency, getLocal('app-lang'));
    },
    previewInvoiceImage(){
      ImagePreview([this.invoice.fileUrl]);
    },
    downloadInvoice: function () {
      window.open(this.invoice.fileUrl)
    },
  }
};
</script>
<style lang="less" scoped>
@import "../../common/css/vars";
.form-container {
  padding-top: 10px;
  // margin-bottom: 30px;
  // background-color: var(--tz-primary-color);
  position: relative;
  border: 0px !important;
}
.headings {
  text-align: left;
  padding-left: 30px;
  padding-top: 30px;
  .name {
    color: #eee;
    font-size: 16px;
    margin-bottom: 25px;
  }

  .main-heading {
    color: white;
    font-size: 36px;
    font-weight: bold;
    line-height: 1em;
  }
}
.form-header-wrapper {
  height: 150px;
  position: relative;
}
.form-wrapper {
  background-color: white;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  padding: 20px 10px;
  // box-shadow: 0px -4px 5px 0px rgba(120, 120, 120, 0.5);
  .invoice-row{
    padding: 7.5px 5px
  }
  .invoice-display-image{
    margin: 10px 0px;
    padding: 5px;
    border-radius:5px;
    background-color:#eee;
  }
}
.invoice-labels{
    color: #0005;
    text-decoration: underline;
    // color: var(--tz-primary-color);
    font-weight: bolder;
  }
.bottom-total{
  padding: 10px 10px;
  background-color: #fffbff;
  align-items: center !important;
  display: flex;
  div:nth-child(1){
    /* width: 80%; */
  }
}
.sticky-bottom{
  background-color: #bbb;
  width: 100vw;
}
.invoice-download{
  text-align: center;
  padding: 10px 0px;
}
.items-title, .item-detail{
  display: flex;
}
.items-title div:nth-child(1){
  width: 75%;
}
.items-title div:nth-child(2){
  white-space: nowrap;
}
.item-detail div:nth-child(2){
  padding-left: 10px;
}
.sub-menu{
  padding: 10px 7.5px;
  text-align: center;
}
.invoice-actions{
  margin-bottom: 7.5px;
}
.w-100{
  width: 100%;
}
.pay-switches{
  text-align: left;
  padding: 10px 15px;

  display: flex;
  align-items: center;
}
.pay-switches >div:nth-child(2){
  width:30%;
  text-align: right;
}
.pay-switches > div:first-child{
  width: 70%;
}
.invoice-display-items{
  padding: 5px;
  border: 1px solid #ccc6;
  border-radius: 10px;
  margin-top: 10px;
}
small{
  font-size: 11px
}
.printable table{
  margin-top: 10px;
  width: 100%;
  border: 1px solid #ccc;
  padding: 5px;
  td{
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 5px;
  }
  th{
    padding: 5px;
    border: 1px 0px 1px 0px solid #ccc;
  }
}
</style>
<style lang="less">
  .printable{
    display: none;
  }
  .no-print{
      display: block;
    }
  @media print{
    html,body{
      width: 100% !important;
      max-width: 100%;
      flex-grow: 1;
    }
    .printable{
      display: block !important;
    }
    .no-print{
      display: none !important;
    }
    .d-flex {
      display: -ms-flexbox !important;
      display: flex !important;
    }
    .w-25 {
      width: 25% !important;
    }

    .w-50 {
      width: 50% !important;
    }

    .w-75 {
      width: 75% !important;
    }

    .w-100 {
      width: 100% !important;
    }
    .row {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      margin-right: -15px;
      margin-left: -15px;
      .col-6:nth-child(odd) {
        text-align: right;
      }
    }
    .col {
      -ms-flex-preferred-size: 0;
      flex-basis: 0;
      -ms-flex-positive: 1;
      flex-grow: 1;
      max-width: 100%;
    }
    .col-6 {
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
    }
    .col-12 {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
    .col-10 {
      -ms-flex: 0 0 80% !important;
      flex: 0 0 80% important;
      max-width: 80% important;
    }
    .col-2 {
      -ms-flex: 0 0 20%;
      flex: 0 0 20%;
      max-width: 20%;
    }
  }
  .tz-popover__content{
    width: 160px!important;
  }
  .tz-popover__content .tz-popover__action .tz-popover__action-text{
    white-space: nowrap !important;
    padding: 5px !important;
}
.text-right{
  text-align: right !important;
}
.invoice-status{
    font-size: 22px;
    font-weight: bolder;
    color: #222 !important;
  }
</style>