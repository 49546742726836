import request from '@/utils/axios'
import  urls  from '../../utils/urls.js';

const baseUrl = urls.baseURL;
// const msURL = urls.localUrl; //@@TODO CHANGE TO REMOTE when pushing
const msURL = baseUrl + '/tz988/v1/invoice/'; // remote url


export default {
    getInvoice: (params = {}) => request.post(msURL + 'get-by-invoiceId', params),
    createPaymentRequest: (params = {}) => request.post(msURL + 'create-payment-request', params)
}